import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import {CloudDownloadFill } from 'react-bootstrap-icons';
// import BeatLoader from "react-spinners/BeatLoader";
import { saveAs } from 'file-saver'
import { Link } from 'react-router-dom';

const Messages = () => {
    const localStorageData =  JSON.parse(localStorage.getItem('kt-auth-react-v'))
    // console.log(localStorageData);
    const token =  localStorageData.api_token;

    const patient_id =  localStorageData.user

    const [offset, setOffset] = useState(1);
    const [fromDate, setFromDate] = useState(); //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState();
    const [sortBy, setSortBy] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [contentLoading, setContentLoading] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [ordersParPage, setOrdersParPage] = useState(30)
    const [messageData, setMessagesData] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [lgshow, setShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('View Message');
    const [messages, setMessages] = useState([]);
    const [viewMode, setViewMode] = useState(false);
    
    useEffect(() => {
        getOrders();
    }, [])

    
    const getOrders = async () => {
        setContentLoading(true);
        
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/messages/${patient_id}`, { headers: {"Authorization" : ` ${token}`} })
            
        const data = res.data;

        setMessages(data);
        
        setTotalItems(data.length);

        setContentLoading(false);
        
    }

    
    const viewDetails = (e, message_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/messages/message/${message_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                setMessagesData(res.data.message);
                setOrderDetails(res.data.reqForm);
                handleShow("", 'View Message', 'View Message', true);

            }
            )
        }catch(err){
            console.log(err);
        }
    }

    const handleShow = (e, title="View Message", button="View Message", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
    
    // console.log(title, button);
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }
    const handleClose = () => {
        setMessagesData([]);
        setShow(false);

    }

    async function downloadReqForm(order_id) {
        const { data } = await getDownloadablePdf(order_id)
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, "requisition.pdf")
    }

    async function getDownloadablePdf(order_id) {
        return axios.get(`${process.env.REACT_APP_API_URL}/orders/get-pdf-requisition/${order_id}`, {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        })
    }
    const formatDate = (date) => {
        // console.log(date);
        // Oct 19, 2022 9:00 AM
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth()+1;
        // month name
        const monthName = d.toLocaleString('default', { month: 'short' });

        const day = d.getDate();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        // hourse to 12 and add am pm
        const hours12 = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const minutes0 = minutes < 10 ? `0${minutes}` : minutes;
        const hours0 = hours12 < 10 ? `0${hours12}` : hours12;
        const seconds0 = seconds < 10 ? `0${seconds}` : seconds;

        const formattedDate = `${monthName} ${day}, ${year} ${hours0}:${minutes0} ${ampm}`;
        return formattedDate;
    }   
    return (
        <>
            <PageTitle breadcrumbs={[]}>My Messages</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Messages <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                </div>
                <div className='card-body py-3'>
                    <div className=''>
                        <div className='row'>
                            {messages.map((message, index) => {
                                if(message.type=== 1){
                                    var message_type = 'Lab Requisition Form'
                                }else{
                                    var message_type= 'Message'
                                }
                                return (
                                <>
                                    <div className="col-xl-4" key={index}>
                                        <div className="card card-xl-stretch mb-xl-10 theme-dark-bg-body shadow">
                                            <div className="card-body d-flex flex-column">
                                                <div className="d-flex flex-column flex-grow-1">
                                                    <div className="text-dark text-hover-primary fw-bolder fs-3 mb-4">{message_type} ({message.order_id})</div>
                                                    <div className="text-dark fw-bolder fs-6 text-dark"><small>{formatDate(message.date)}</small></div>
                                                        <div className="mt-5" onClick={e=>viewDetails(e, message._id)}>
                                                            
                                                            <button className="btn btn-primary btn-sm">View</button>
                                                            {/* <span className="text-dark fw-bolder fs-6 lh-0">+ 28 this week</span> */}
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                )
                            })}
                            
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <div className="fs-5">
                        We are pleased that you selected Horizon Online Lab for your testing requirements. This is your order ID number: <span className='fw-bold'>{orderDetails?.order_id}</span> We have attached the form you will need when you visit the LabCorp collection site to safeguard your privacy. You can print the attachment from your online account's <Link to="/orders" className='text-decoration-none fw-bold'>My Orders / Results</Link> tab or fax it directly to the lab. You can visit any LabCorp facility because LabCorp will carry out your testing. Please click here to locate a LabCorp location in your area. During the laboratory's business hours, you can visit whenever you want. Making an appointment is unnecessary, but you can call the lab directly. Please get in touch with us if you need any help with your testing center. Sign in using the name you provided when you get to the LabCorp collection site. There is no requirement to provide insurance information or show your identification. You will receive an email notification as soon as your results are completed.  
                        </div>
                        <div className="mt-3 fs-5">
                            <span className='fw-bold'>
                                Regards, Horizon Online Lab 
                            </span>
                            <div className="mt-4">
                                <small>
                                NOTES IMPORTANT: You must bring the attached lab form with you to the LabCorp co collection site because all testing sites are locally operated and have their policies and procedures. 
                                </small>
                            </div>
                        </div>

                        {orderDetails?.requisition_form || orderDetails?.requisition_form !== null || orderDetails?.requisition_form !== '' ?
                        <div className='row pb-4'>
                            <div className='col-md-6 fs-4 mt-4'>
                                <Form.Group controlId="TestName">
                                    <Form.Label className='fw-bold'>Lab Requisition Form</Form.Label>: &nbsp;
                                    <button onClick={e=>downloadReqForm(orderDetails?._id)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Download Report'
                                        >
                                        <CloudDownloadFill />
                                    </button>
                                </Form.Group>
                            </div>
                            
                        </div>: ''}
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" >
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>
        </>
    )
}

export default Messages
